:root {
  --s-logo-negative: url(/images/logo-designstudios-white.svg);
  --s-textcolor: #313638;
  --s-linkcolor: #313638;
  --s-linkcolor-hover: #0059AC;
}
:root {
  --deskWidth: 1600px;
  --deskSpace: 19px;
  --deskMaxWidth: calc(100vw - (2 * var(--deskSpace)));
}
:root {
  --spacePart: 24px;
}
@media (max-width: 1023px) {
  :root {
    --spacePart: 9px;
  }
}
@media (max-width: 767px) {
  :root {
    --spacePart: 9px;
  }
}
:root {
  --spaceUnit: 56px;
}
@media (max-width: 1023px) {
  :root {
    --spaceUnit: 21px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceUnit: 21px;
  }
}
:root {
  --spaceTotal: 80px;
}
@media (max-width: 1023px) {
  :root {
    --spaceTotal: 30px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceTotal: 30px;
  }
}
:root {
  --js-breakpoint: breakpointLarge;
}
@media (max-width: 1023px) {
  :root {
    --js-breakpoint: breakpointMedium;
  }
}
@media (max-width: 767px) {
  :root {
    --js-breakpoint: breakpointSmall;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #0059AC;
  line-height: 1.55555556;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
}
.button:hover,
.button:focus {
  color: #fff;
  background-color: #004c93;
}
.button:active {
  background-color: #003160;
}
/* inter-300 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: url('/extras/fonts/inter-v12-latin-300.eot');
  /* IE9 Compat Modes */
  src: url('/extras/fonts/inter-v12-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/extras/fonts/inter-v12-latin-300.woff2') format('woff2'), /* Super Modern Browsers */ url('/extras/fonts/inter-v12-latin-300.woff') format('woff'), /* Modern Browsers */ url('/extras/fonts/inter-v12-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */ url('/extras/fonts/inter-v12-latin-300.svg#Inter') format('svg');
  /* Legacy iOS */
}
/* inter-600 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('/extras/fonts/inter-v12-latin-600.eot');
  /* IE9 Compat Modes */
  src: url('/extras/fonts/inter-v12-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/extras/fonts/inter-v12-latin-600.woff2') format('woff2'), /* Super Modern Browsers */ url('/extras/fonts/inter-v12-latin-600.woff') format('woff'), /* Modern Browsers */ url('/extras/fonts/inter-v12-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */ url('/extras/fonts/inter-v12-latin-600.svg#Inter') format('svg');
  /* Legacy iOS */
}
/* source-serif-pro-italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Serif Pro';
  font-style: italic;
  font-weight: 400;
  src: url('/extras/fonts/source-serif-pro-v15-latin-italic.eot');
  /* IE9 Compat Modes */
  src: url('/extras/fonts/source-serif-pro-v15-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/extras/fonts/source-serif-pro-v15-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */ url('/extras/fonts/source-serif-pro-v15-latin-italic.woff') format('woff'), /* Modern Browsers */ url('/extras/fonts/source-serif-pro-v15-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('/extras/fonts/source-serif-pro-v15-latin-italic.svg#SourceSerifPro') format('svg');
  /* Legacy iOS */
}
* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
  background: none;
  font-size: 100%;
}
*::selection {
  background: #DADADA;
}
.hidden,
.cb-invisible {
  display: none !important;
}
li {
  list-style-position: inside;
}
img,
audio,
video,
iframe {
  float: left;
  width: 100%;
}
strong {
  font-weight: 600;
}
sup,
sub {
  position: relative;
  vertical-align: baseline;
  top: -0.8em;
  font-size: 70%;
}
sub {
  top: 0.4em;
}
address {
  font-style: normal;
}
input,
textarea {
  border-radius: 0;
  font-size: 18px;
  font-family: 'Inter', sans-serif;
  line-height: 1.55555556;
}
.unit caption {
  display: none;
}
.flag {
  background: #0059AC;
  color: #fff;
}
img.cb-loading {
  background-size: 30px 30px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader.svg);
}
.cbdModule {
  float: left;
  width: 100%;
}
#view .cbdModule.is-empty {
  display: none;
}
.cbDynamicContent__element {
  float: left;
  width: 100%;
}
.area {
  float: left;
  width: 100%;
}
#view .area:empty {
  display: none;
}
.unit {
  float: left;
  width: 100%;
  margin-top: var(--spaceUnit);
  margin-bottom: var(--spaceUnit);
  position: relative;
}
.unit__background {
  float: left;
  width: 100%;
}
.unit__content {
  float: left;
  width: 100%;
}
.unit__head,
.unit__body,
.unit__foot {
  float: left;
  width: 100%;
}
.unit--fold div.less,
.unit--fold div.more {
  float: left;
  width: 100%;
}
.unit--fold .ctrl {
  float: left;
  width: 100%;
}
.part {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
  position: relative;
}
.pict a {
  float: left;
  width: 100%;
}
.pict img {
  width: 100%;
}
.pict img:not(.svg) {
  width: auto;
  max-width: 100% !important;
}
#root#root#root img.zoom {
  display: none !important;
}
#edit .part video {
  pointer-events: none;
}
.cb-image-figure {
  float: left;
  width: 100%;
}
.cb-image-container {
  float: left;
  width: 100%;
  position: relative;
}
.cb-image-caption {
  float: left;
  width: 100%;
  margin-top: 6px;
}
#disp.zoom .cb-image-figure {
  height: 100%;
}
#disp.zoom .cb-image-container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom .cb-image-caption {
  display: none;
}
.cb-album .cb-image-figure {
  opacity: 1 !important;
  display: block !important;
}
.cb-album .cb-image-container {
  position: static;
}
.cb-album .cb-image-caption {
  display: none;
}
div.load {
  background: none !important;
}
div.load a.load {
  display: inline-block;
  padding-left: 26px;
  background-size: 16px 16px;
  background-position: 0 6px;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-download-black.svg);
}
.show a {
  float: left;
  width: 100%;
  display: block;
}
#edit .line {
  padding-top: 5px;
  padding-bottom: 5px;
}
.line hr {
  float: left;
  width: 100%;
  border-bottom: 1px solid var(--s-textcolor);
}
.line hr.bold {
  border-bottom-width: 2px;
}
.line hr.dash {
  border-bottom-style: dashed;
}
.line hr.spot {
  border-bottom-style: dotted;
}
#edit div.code {
  position: relative;
  min-height: 30px;
}
#edit div.code:after {
  content: 'Code';
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: #aaa;
  opacity: 0.6;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 11px;
  letter-spacing: 0.2em;
  padding: 0 20px;
}
.cb-code-executable {
  float: left;
  width: 100%;
}
.part.cb-youtube iframe,
.part.cb-vimeo iframe {
  height: 100%;
  background-color: #fff;
}
.part.cb-share {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.part.cb-share .cb-service {
  float: left;
  margin-left: 16px;
}
.part.cb-share .cb-service:first-child {
  margin-left: 0;
}
.part.cb-share .cb-service * {
  float: left;
}
.part.cb-share .cb-service a {
  float: left;
  width: 50px;
  height: 50px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  box-sizing: border-box;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 10000px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.part.cb-share .cb-service a:hover {
  filter: invert(1);
}
.part.cb-share .cb-service.cb-service-facebook-share a {
  background-size: auto 24px;
  background-image: url(/images/cb-default/socialShare-facebook-black.svg);
}
.part.cb-share .cb-service.cb-service-twitter a {
  background-size: auto 18px;
  background-image: url(/images/cb-default/socialShare-twitter-black.svg);
}
.text-section {
  float: left;
  width: 100%;
}
.list {
  float: left;
  width: 100%;
}
.list--bullet li {
  list-style: none;
  padding-left: 24px;
  position: relative;
}
.list--bullet li:before {
  content: '–';
  position: absolute;
  left: 0;
  top: 0;
  font-size: 22px;
  line-height: 1.5;
}
@media (max-width: 1023px) {
  .list--bullet li {
    padding-left: 12px;
  }
  .list--bullet li:before {
    font-size: 14px;
    line-height: 1.42857143;
  }
}
.list--numbered {
  counter-reset: numberedList;
}
.list--numbered li {
  list-style: none;
  padding-left: 24px;
  position: relative;
}
.list--numbered li:before {
  content: counter(numberedList) ".";
  counter-increment: numberedList;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 22px;
  line-height: 1.5;
}
@media (max-width: 1023px) {
  .list--numbered li {
    padding-left: 12px;
  }
  .list--numbered li:before {
    font-size: 14px;
    line-height: 1.42857143;
  }
}
#expo {
  float: left;
  width: 100%;
  position: relative;
}
#slides {
  float: left;
  width: 100%;
  height: 0 !important;
  max-width: 100% !important;
  max-height: 100% !important;
  padding-bottom: 23.4375%;
  position: relative;
}
#slides .slide {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
}
#slides a {
  display: block;
  width: 100%;
  height: 100%;
}
#slides a.null {
  cursor: default;
}
.cb-slides .cb-image-figure {
  height: 100%;
}
.cb-slides .cb-image-container {
  height: 100% !important;
}
#slides img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
#expo div.link {
  display: none;
  float: left;
  width: 100%;
  height: 0;
}
#expo a.link {
  position: absolute;
  top: 50%;
  z-index: 2;
  width: 30px;
  height: 50px;
  transform: translateY(-50%);
  background: #0059AC;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#expo a.link.prev {
  left: 20px;
}
#expo a.link.next {
  right: 20px;
}
table.link,
table.link tbody {
  float: left;
  display: block;
  width: 100%;
  height: 0;
}
.cb-index-all {
  display: block;
  position: absolute;
  left: 0;
  bottom: 20px;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.cb-index-all td {
  float: left;
  display: block;
  margin: 0 10px;
}
.cb-index-all td.init {
  margin-left: 0;
}
.cb-index-all td.exit {
  margin-right: 0;
}
.cb-index-all a {
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background: #fff;
  border-radius: 16px;
  transition: all 0.4s;
}
.cb-index-all td.this a {
  background: #0059AC;
}
.cb-index-some {
  display: block;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
}
.cb-index-some td {
  float: left;
  display: block;
  color: #fff;
}
.cb-index-some td:first-child:after {
  display: inline-block;
  content: '/';
  margin: 0 5px;
}
.cb-index-some a {
  color: #fff;
}
.cb-index-some a:hover,
.cb-index-some a:focus {
  color: #fff;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.8);
}
#disp {
  position: absolute;
  z-index: 5001;
  top: 0 !important;
  left: 50% !important;
  transform: translateX(-50%);
  width: 1000px !important;
  max-width: 90%;
  min-height: 100vh;
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  margin: 0 !important;
  box-sizing: border-box;
  padding: 80px 0 !important;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  line-height: 1.42857143;
  color: var(--s-textcolor);
}
@media (max-width: 767px) {
  #disp {
    padding: 20px 0 !important;
  }
}
.disp-wrapper {
  float: left;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 40px;
}
@media (max-width: 767px) {
  .disp-wrapper {
    padding: 15px;
  }
}
#disp form {
  float: left;
}
.disp-wrapper > form {
  width: 100%;
}
@media (max-width: 767px) {
  #disp form {
    width: 100%;
  }
}
#disp .fail {
  color: #ae2121;
}
#disp .part {
  margin-top: 4px;
  margin-bottom: 4px;
}
#disp a.mail {
  float: left;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
#disp .head,
#disp .body,
#disp .foot {
  float: left;
  width: 100%;
  position: relative;
}
#disp .body {
  margin: 20px 0;
}
#disp .foot form + form {
  float: right;
}
#disp h2 {
  font-size: 14px;
  line-height: 1.71428571;
  font-weight: normal;
  text-transform: uppercase;
  color: #0059AC;
  width: 100%;
  box-sizing: border-box;
  padding-right: 30px;
}
#disp h3 {
  color: #0059AC;
  font-size: 16px;
  font-weight: 600;
  padding: 20px 0;
  width: 100%;
}
#disp .head .ctrl {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
#disp .head .ctrl > div {
  float: left;
}
#disp .head .ctrl a {
  float: left;
  display: block;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 16px;
  height: 24px;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
#disp .foot input,
#disp .foot a {
  float: left;
  background-color: #0059AC;
  line-height: 1.55555556;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  line-height: 1.42857143;
}
#disp .foot input:hover,
#disp .foot a:hover,
#disp .foot input:focus,
#disp .foot a:focus {
  color: #fff;
  background-color: #004c93;
}
#disp .foot input:active,
#disp .foot a:active {
  background-color: #003160;
}
#disp .foot input.next,
#disp .foot a.next {
  float: right;
}
#disp.mail .foot input {
  float: right;
}
@media (max-width: 767px) {
  #disp .foot input,
  #disp .foot a {
    width: 100%;
    margin: 5px 0;
  }
  #disp .foot input:first-child,
  #disp .foot a:first-child {
    margin-top: 0;
  }
  #disp .foot input:last-child,
  #disp .foot a:last-child {
    margin-bottom: 0;
  }
}
#disp fieldset {
  float: left;
  width: 100%;
  position: relative;
  margin: 4px 0;
}
#disp fieldset.tiny {
  width: 48%;
}
#disp .head fieldset,
#disp .foot fieldset {
  margin: 0;
}
@media (max-width: 767px) {
  #disp fieldset.tiny {
    width: 100%;
  }
}
#disp label.name {
  float: left;
  width: 30%;
  margin-right: 4%;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  #disp label.name {
    width: 100%;
    margin-right: 0;
  }
}
#disp input.text,
#disp textarea {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  min-height: 26px;
  box-sizing: border-box;
  padding: 2px 10px;
}
#disp input.text.fail,
#disp textarea.fail {
  border: 2px solid #ae2121;
}
#disp textarea {
  min-height: 100px;
  padding: 6px 10px;
}
@media (max-width: 767px) {
  #disp input.text,
  #disp textarea {
    width: 100%;
  }
}
#disp select {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  height: 26px;
}
@media (max-width: 767px) {
  #disp select {
    width: 100%;
  }
}
#disp input + label,
#disp input + label {
  display: block;
  padding-left: 30px;
}
#disp input.radio,
#disp input.checkbox {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  height: 20px;
  width: 20px;
}
.cb-sitemap-target {
  float: left;
  margin-bottom: 20px;
  color: #fff;
  padding: 5px 10px;
  background-color: #ae2121;
}
.cb-sitemap-contents {
  float: left;
  width: 100%;
}
.cb-sitemap-contents li {
  list-style: none;
  padding-left: 20px;
  position: relative;
}
.cb-sitemap-contents li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.cb-zoom-indicator {
  position: absolute;
  z-index: 2;
  right: 15px;
  bottom: 15px;
  width: 36px;
  height: 36px;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  background-size: 18px 18px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-zoom-white.svg);
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
a.zoom .cb-zoom-indicator {
  opacity: 1;
  transform: scale(1);
}
@media (max-width: 1023px) {
  .cb-zoom-indicator {
    right: 10px;
    bottom: 10px;
    width: 24px;
    height: 24px;
    background-size: 12px 12px;
    opacity: 1;
    transform: scale(1);
  }
}
#disp.zoom {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  max-width: 100%;
  height: 100%;
  background: none;
  padding: 0 !important;
  display: block !important;
  transform: none;
}
#disp.zoom .disp-wrapper {
  padding: 0;
  background: none;
  height: 100%;
}
#disp.zoom div.head h2 {
  display: none;
}
#disp.zoom div.head div.ctrl {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 2;
}
@media (max-width: 767px) {
  #disp.zoom div.head div.ctrl {
    top: 20px;
    right: 20px;
  }
}
#disp.zoom div.head div.ctrl > div {
  float: left;
  margin: 0 10px;
}
#disp.zoom div.head div.ctrl > div:first-child {
  margin-left: 0;
}
#disp.zoom div.head div.ctrl > div:last-child {
  margin-right: 0;
}
#disp.zoom div.head div.ctrl a {
  float: left;
  display: block;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-white.svg);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#disp.zoom div.head div.ctrl .next a {
  background-image: url(/images/cb-default/cb-arrow-right-white.svg);
}
#disp.zoom div.head div.ctrl .quit a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
  background-size: 16px 16px;
}
#disp.zoom div.head div.ctrl a:hover {
  transform: scale(1.1);
}
#disp.zoom div.head div.ctrl a:active {
  transform: scale(1);
}
#disp.zoom div.body {
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 80px 30px;
  margin: 0;
}
@media (max-width: 767px) {
  #disp.zoom div.body {
    padding: 60px 20px;
  }
}
#disp.zoom div.body > div {
  float: left;
  width: 100% !important;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body a {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body img {
  float: left;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
#disp.zoom div.foot {
  display: none;
}
.ie11 #disp.zoom {
  position: absolute;
}
.ie11 #disp.zoom div.body,
.ie11 #disp.zoom div.body > div,
.ie11 #disp.zoom div.body a {
  height: auto;
  display: block;
}
.ie11 #disp.zoom div.body img {
  float: none;
  position: relative;
  height: auto;
  display: block;
  margin: 0 auto;
}
#disp.srch {
  display: block !important;
}
#disp.srch h2 {
  display: none;
}
#disp.srch .disp-wrapper {
  background: none;
  padding: 0;
}
#disp.srch .head .ctrl a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
}
#disp.srch div.body {
  margin: 0;
}
#disp.srch fieldset {
  margin: 0;
}
#disp.srch label.name {
  display: none;
}
#disp.srch input.text {
  font-family: 'Inter', sans-serif;
  background-color: transparent;
  margin-top: 40px;
  width: 100%;
  color: #fff;
  font-size: 50px;
  line-height: 1;
  border-bottom: 1px solid #fff;
  padding: 20px 0;
  -webkit-font-smoothing: antialiased;
  -webkit-appearance: none;
}
@media (max-width: 767px) {
  #disp.srch input.text {
    font-size: 18px;
  }
}
.cb-result {
  float: left;
  width: 100%;
  color: #fff;
  font-size: 18px;
  text-align: left;
  -webkit-font-smoothing: antialiased;
}
.cb-result p {
  margin-top: 40px;
}
.cb-result a {
  color: #fff;
  text-decoration: none;
}
.cb-result a:hover {
  color: #fff;
  text-decoration: underline;
}
.cb-result table {
  float: left;
  width: 100%;
  border-collapse: collapse;
}
.cb-result tbody {
  width: 100%;
}
.cb-result th {
  padding-top: 20px;
  font-size: 24px;
  line-height: 1;
  text-align: left;
}
.cb-result th:first-child {
  padding-right: 40px;
  width: 80px;
}
.cb-result td {
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
.cb-result th,
.cb-result td {
  vertical-align: middle;
}
@media (max-width: 767px) {
  .cb-result {
    font-size: 14px;
  }
  .cb-result th {
    padding-top: 10px;
    font-size: 18px;
  }
  .cb-result th:first-child {
    padding-right: 10px;
    width: 54px;
  }
  .cb-result td {
    padding-bottom: 10px;
    padding-top: 5px;
  }
}
.unit.form form {
  float: left;
  width: 100%;
}
.cb-form-required {
  float: left;
  width: 100%;
}
.unit.form fieldset {
  float: left;
  width: 100%;
  position: relative;
}
.unit.form div.ctrl {
  float: left;
  width: 100%;
}
.unit.form div.ctrl.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
@media (min-width: 768px) {
  .unit.form .tile div.ctrl {
    float: right;
    width: 66%;
  }
}
.unit.form .name {
  float: left;
  width: 100%;
  padding-bottom: 5px;
}
@media (min-width: 768px) {
  .unit.form .tile .name {
    width: 30%;
    min-height: 40px;
    padding-top: 6px;
    padding-bottom: 6px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }
}
.unit.form input.text,
.unit.form textarea {
  float: left;
  width: 100%;
  box-sizing: border-box;
  background: #F0F0F0;
  min-height: 40px;
  padding: 5px 10px;
  -webkit-appearance: none;
}
.unit.form input.text.fail,
.unit.form textarea.fail {
  background-color: #e7bcbc;
}
.unit.form textarea {
  padding: 10px;
}
@media (min-width: 768px) {
  .unit.form .tile input.text,
  .unit.form .tile textarea {
    float: right;
    width: 66%;
  }
}
.unit.form div.tick div.ctrl > div {
  float: left;
  width: 100%;
  position: relative;
  padding: 4px 0;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile div.ctrl > div {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}
.unit.form div.tick label {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 26px;
}
.unit.form div.tick input {
  position: absolute;
  left: 0;
  top: 4px;
  width: 18px;
  margin-right: 6px;
  height: 28px;
  vertical-align: top;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile input {
    top: 6px;
  }
}
.unit.form select {
  float: left;
  width: 100%;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  line-height: 1.55555556;
  background: #F0F0F0;
  height: 40px;
  border: 1px solid var(--s-textcolor);
  padding: 5px;
}
.unit.form .ship fieldset > div {
  float: left;
  width: 100%;
}
.unit.form .ship fieldset > div.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
.unit.form .ship div.chop {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.unit.form .ship div.chop input {
  float: left;
  width: 100%;
  display: block;
  font-size: 12px;
}
.unit.form .ship div.chop span {
  float: left;
}
.unit.form .ship div.chop a {
  float: left;
  display: block;
  margin-left: 10px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 12px;
  height: 12px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
@media (min-width: 768px) {
  .unit.form .ship.tile fieldset > div {
    float: right;
    width: 66%;
    min-height: 40px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
}
.unit.form .submit {
  float: right;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
  background-color: #0059AC;
  line-height: 1.55555556;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
}
.unit.form .submit:hover,
.unit.form .submit:focus {
  color: #fff;
  background-color: #004c93;
}
.unit.form .submit:active {
  background-color: #003160;
}
.calendar {
  position: absolute !important;
  z-index: 2;
  left: 34% !important;
  top: 100% !important;
  right: unset !important;
  background: #fff;
  width: 200px;
  margin: 10px 0 0 0 !important;
  box-shadow: 0 0 6px rgba(160, 160, 160, 0.4);
  font-size: 14px;
  line-height: 1.14285714;
  padding: 20px;
}
.calendar table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.calendar th,
.calendar td {
  text-align: center;
  padding: 2px;
}
.calendar tr.month th {
  padding-bottom: 10px;
}
.calendar tr.month th:first-child a,
.calendar tr.month th:last-child a {
  float: left;
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
}
.calendar tr.month th:last-child a {
  float: right;
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.calendar td.today a {
  color: var(--s-textcolor);
  text-decoration: underline;
}
.unit.form .recaptcha-info {
  color: #aaa;
}
.unit.form .recaptcha-info a {
  color: #aaa;
}
.unit.form .recaptcha-info a:hover,
.unit.form .recaptcha-info a:focus {
  text-decoration: underline;
}
.unit.form .part.fail {
  font-size: 16px;
  line-height: 1.25;
  border-left: 4px solid #ae2121;
  box-sizing: border-box;
  padding: 20px;
  background-color: #e7bcbc;
  color: #ae2121;
}
.unit.form div.cb-form-sent {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.unit.form div.cb-form-sent a {
  float: right;
  color: #5abb55;
  font-size: 14px;
}
#disp.two-step-verification div.body {
  overflow: hidden;
}
#disp.two-step-verification p {
  float: left;
  width: 100%;
}
.two-step-verification-container {
  float: left;
  width: 100%;
  min-width: 300px;
  margin-top: 10px;
  height: 350px;
  position: relative;
}
.two-step-verification-container a {
  background-color: #0059AC;
  line-height: 1.55555556;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
}
.two-step-verification-container a:hover,
.two-step-verification-container a:focus {
  color: #fff;
  background-color: #004c93;
}
.two-step-verification-container a:active {
  background-color: #003160;
}
.two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  background-size: 100px 100px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader-black.svg);
}
.two-step-verification-container.loaded .two-step-verification__div {
  background-image: none;
}
.table {
  float: left;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.table-layout-fixed .table {
  table-layout: fixed;
}
.table tr {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.table-head tr {
  border-top: none;
}
.table th {
  font-weight: normal;
  padding: 5px 10px;
  vertical-align: top;
}
.table th.init {
  padding-left: 0;
}
.table th.exit {
  padding-right: 0;
}
.table th.align-left {
  text-align: left;
}
.table th.align-center {
  text-align: center;
}
.table th.align-right {
  text-align: right;
}
.table--headline th {
  font-weight: 600;
}
.table td {
  padding: 5px 10px;
  vertical-align: top;
}
.table td.init {
  padding-left: 0;
}
.table td.exit {
  padding-right: 0;
}
.table td.align-left {
  text-align: left;
}
.table td.align-center {
  text-align: center;
}
.table td.align-right {
  text-align: right;
}
#edit .table td {
  border-left: 1px dashed #e0e0e0;
  border-right: 1px dashed #e0e0e0;
}
.table--footer .table-foot td {
  font-weight: 600;
}
@media (max-width: 767px) {
  .part--table .table {
    font-size: 2.34680574vw;
  }
}
html,
body {
  float: left;
  width: 100%;
  min-height: 100vh;
}
#home {
  float: left;
  height: 50px;
}
.logo {
  width: auto;
  height: 100%;
}
.cb-toggle-target-active .logo {
  content: var(--s-logo-negative);
}
@media (max-width: 1023px) {
  .cb-toggle-target-active .logo {
    display: none;
  }
}
#head {
  float: left;
  width: 100%;
}
.wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  line-height: 1.55555556;
  color: var(--s-textcolor);
  overflow: hidden;
  hyphens: none;
  -webkit-font-smoothing: antialiased;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: var(--deskMaxWidth);
  width: var(--deskWidth);
  position: relative;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
.section {
  float: left;
  width: 100%;
  position: relative;
}
.section--header {
  z-index: 2000;
}
#edit .section--header {
  margin-bottom: 50px;
}
.container--head {
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navi-contact {
  float: left;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 60px;
}
.navi-contact .vcard {
  --s-linkcolor: #FFFFFF;
  --s-linkcolor-hover: #B3CDE5;
  text-align: right;
}
.social {
  float: left;
  margin-top: 60px;
}
.backlink {
  float: left;
  margin-left: -13px;
  margin-bottom: var(--spaceTotal);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 60px;
  height: 60px;
  background-size: 35px 25px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-slider-prev.svg);
}
.backlink:hover,
.backlink:focus {
  background-position: 30% 50%;
}
.section--footer {
  background-color: #00487B;
}
.footarea {
  float: left;
  width: 100%;
}
.footpart {
  float: left;
}
.services {
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.service-links {
  float: left;
}
.cbdModule--serviceLinkOne,
.cbdModule--serviceLinkTwo {
  max-width: 250px;
}
#social {
  float: left;
}
#social .meta {
  float: left;
  width: 40px;
  height: 40px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  margin-right: 25px;
  transition: all 0.4s;
}
#social .meta:last-child {
  margin-right: 0;
}
#social .meta.service_instagram {
  background-image: url(/images/instagram-50x50-white.svg);
}
#social .meta.service_linkedin {
  background-image: url(/images/linkedin-50x50-white.svg);
}
a {
  color: var(--s-linkcolor);
  text-decoration: underline;
  transition: all 0.4s;
}
a:hover,
a:focus {
  color: var(--s-linkcolor-hover);
}
h1,
h5 {
  font-size: 18px;
  line-height: 1.55555556;
  color: var(--s-textcolor);
  --s-textcolor: #0059AC;
  padding-left: 52px;
  background: linear-gradient(var(--s-textcolor), var(--s-textcolor));
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-size: 43px 2px;
}
@media (max-width: 1023px) {
  h1,
  h5 {
    font-size: 12px;
    line-height: 1.5;
  }
}
h2 {
  font-size: 28px;
  line-height: 1.42857143;
  color: var(--s-textcolor);
  --s-textcolor: #0059AC;
  --s-linkcolor: #0059AC;
  --s-linkcolor-hover: #313638;
  font-weight: 300;
}
@media (max-width: 1023px) {
  h2 {
    font-size: 20px;
    line-height: 1.35;
  }
}
h3 {
  font-size: 28px;
  line-height: 1.42857143;
  color: var(--s-textcolor);
}
@media (max-width: 1023px) {
  h3 {
    font-size: 18px;
    line-height: 1.5;
  }
}
h4 {
  font-size: 22px;
  line-height: 1.5;
  color: var(--s-textcolor);
  --s-textcolor: #0059AC;
  --s-linkcolor: #0059AC;
  --s-linkcolor-hover: #313638;
  font-weight: 300;
}
@media (max-width: 1023px) {
  h4 {
    font-size: 14px;
    line-height: 1.42857143;
  }
}
h6 {
  font-weight: 400;
  font-family: 'Source Serif Pro', serif;
  color: var(--s-textcolor);
  --s-textcolor: #DADADA;
  font-size: 32px;
  line-height: 1.25;
}
@media (max-width: 1023px) {
  h6 {
    font-size: 16px;
    line-height: 1.375;
  }
}
.ns-slider h6 {
  font-family: 'Inter', sans-serif;
  --s-textcolor: #313638;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.55555556;
}
@media (max-width: 1023px) {
  .ns-slider h6 {
    font-size: 12px;
    line-height: 1.33333333;
  }
}
.loud {
  font-size: 28px;
  line-height: 1.42857143;
  color: var(--s-textcolor);
}
@media (max-width: 1023px) {
  .loud {
    font-size: 20px;
    line-height: 1.35;
  }
}
.norm {
  font-size: 18px;
  line-height: 1.5;
  color: var(--s-textcolor);
}
@media (max-width: 1023px) {
  .norm {
    font-size: 14px;
    line-height: 1.42857143;
  }
}
.skew {
  font-family: 'Source Serif Pro', serif;
  color: #0059AC;
  font-size: 32px;
  line-height: 1.25;
}
@media (max-width: 1023px) {
  .skew {
    font-size: 16px;
    line-height: 1.375;
  }
}
.ns-slider p {
  font-size: 18px;
  line-height: 1.55555556;
}
@media (max-width: 1023px) {
  .ns-slider p {
    font-size: 12px;
    line-height: 1.33333333;
  }
}
.cb-image-caption {
  font-size: 18px;
  line-height: 1.33333333;
}
@media (max-width: 1023px) {
  .cb-image-caption {
    font-size: 12px;
    line-height: 1.33333333;
  }
}
.foottitle {
  font-weight: 600;
}
.vcard {
  float: left;
  color: var(--s-textcolor);
  --s-textcolor: #FFFFFF;
  --s-linkcolor: #FFFFFF;
  --s-linkcolor-hover: #B3CDE5;
  font-size: 18px;
  line-height: 1.55555556;
}
.vcard a {
  text-decoration: none;
}
@media (max-width: 1023px) {
  .vcard {
    font-size: 16px;
    line-height: 1.5;
  }
}
.claim {
  font-weight: 600;
  color: var(--s-textcolor);
  --s-textcolor: #FFFFFF;
}
.copyright {
  color: var(--s-textcolor);
  --s-textcolor: #B3CDE5;
}
@media (max-width: 1023px) {
  .copyright {
    font-size: 14px;
    line-height: 1.42857143;
  }
}
.servicelink {
  color: var(--s-linkcolor);
  --s-linkcolor: #B3CDE5;
  --s-linkcolor-hover: #FFFFFF;
}
.servicelink .open {
  text-decoration: none;
}
@media (max-width: 1023px) {
  .servicelink {
    font-size: 14px;
    line-height: 1.42857143;
  }
}
.area--one .unitTwo .unit__background {
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.area--one .unitThree .unit__body {
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.area--one .unitThree .part.pict * {
  height: 100%;
}
.area--one .unitThree .part.pict img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.area--one .unitThree .part.pict {
  margin: 0;
}
.area--one .unitFour {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.area--one .unitFour .part.text {
  text-align: center;
}
.area--one .unitFour .unit__background {
  width: 80px;
  max-width: 100%;
}
.area--one .unitFive .unit__body {
  background-image: url(/images/quotation-mark.svg);
  background-repeat: no-repeat;
  background-position: 0 0;
}
.area--one .unitFive--image .unit__background {
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.partAlign--left {
  text-align: left;
}
.partAlign--center {
  text-align: center;
}
.partFileSize--social {
  width: 40px;
  max-width: 100%;
}
.partFileSize--social + .partFileSize--social {
  margin-left: 25px;
}
.part--widget .cb-googlemapscontainer .cb-undraggable {
  height: 100% !important;
}
body.cb-toggle-target-active {
  overflow: hidden;
}
.mobile-navigation div.sub2 {
  display: none;
}
.togglenavigation {
  float: left;
  position: relative;
  width: 52px;
  height: 28px;
  cursor: pointer;
}
@media (max-width: 1023px) {
  .togglenavigation {
    width: 36px;
    height: 20px;
  }
}
.tline {
  position: absolute;
  top: 0;
  right: 0;
  width: 52px;
  height: 2px;
  background-color: #0059AC;
  border-radius: 2px;
  transition: all 0.3s;
}
.tline--1 {
  width: 45px;
}
.tline--2,
.tline--3 {
  top: 50%;
  width: 32px;
  transform: translateY(-50%);
}
.tline--4 {
  top: unset;
  bottom: 0;
}
@media (max-width: 1023px) {
  .togglenavigation {
    width: 36px;
    height: 20px;
  }
  .tline {
    width: 36px;
  }
  .tline--1 {
    width: 31px;
  }
  .tline--2,
  .tline--3 {
    width: 22px;
  }
}
.togglenavigation:hover .tline--1,
.togglenavigation:hover .tline--4 {
  transition: all 0.3s 0.2s;
  width: 100%;
}
.togglenavigation:hover .tline--2,
.togglenavigation:hover .tline--3 {
  width: 100%;
  right: 0;
}
.togglenavigation.cb-toggle-active .tline {
  background-color: #FFFFFF;
}
.togglenavigation.cb-toggle-active .tline--1,
.togglenavigation.cb-toggle-active .tline--4 {
  opacity: 0;
  width: 0;
  right: 50%;
  transition: all 0.3s;
}
.togglenavigation.cb-toggle-active .tline--2,
.togglenavigation.cb-toggle-active .tline--3 {
  width: 100%;
  right: 0;
  transition: all 0.3s, transform 0.3s 0.2s;
}
.togglenavigation.cb-toggle-active .tline--2 {
  transform: translateY(-50%) rotate(-45deg);
}
.togglenavigation.cb-toggle-active .tline--3 {
  transform: translateY(-50%) rotate(45deg);
}
.mobile-navigation {
  position: relative;
  z-index: 1999;
}
.mobile-navigation:before {
  content: '';
  position: fixed;
  left: 0;
  right: 0;
  top: 110px;
  bottom: 100%;
  will-change: bottom, top, background;
  transition: bottom 0.14s, top 0.14s, background 0.2s;
}
body.cb-toggle-target-active .mobile-navigation {
  width: 100%;
  height: 100%;
  overflow-x: scroll;
}
body.cb-toggle-target-active .mobile-navigation:before {
  bottom: 0;
  top: 0;
  background-color: #00487B;
  transition: bottom 0.2s, top 0.2s, background 0.2s;
}
.mobile-navi-animation {
  display: none;
  float: left;
  width: 100%;
  transform: translateY(0);
  opacity: 1;
}
.cb-toggle-target-active .mobile-navi-animation {
  display: block;
}
.subnavi {
  float: left;
  width: 100%;
}
@media (max-width: 1023px) {
  .subnavi {
    overflow-x: scroll;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .subnavi::-webkit-scrollbar {
    display: none;
  }
}
.mobile-navigation div.navi {
  float: left;
}
.mobile-navigation div.navi > .item {
  float: left;
}
.mobile-navigation div.navi > .item > .menu {
  display: block;
}
.mobile-navigation div.sub1 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}
.mobile-navigation div.sub1 > .item {
  margin: 7px 0;
}
.mobile-navigation div.sub1 > .item.init {
  margin-top: 0;
}
.mobile-navigation div.sub1 > .item.exit {
  margin-bottom: 0;
}
.mobile-navigation div.sub1 > .item > .menu {
  font-size: 36px;
  line-height: 1.19444444;
  --s-linkcolor: #FFFFFF;
  transition: all 0.4s;
  text-decoration: none;
}
.mobile-navigation div.sub1 > .item > .menu:hover,
.mobile-navigation div.sub1 > .item > .menu:focus {
  --s-linkcolor-hover: #B3CDE5;
}
.subnavi div.sub2 {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.subnavi div.sub2 > .item {
  margin: 0px 30px;
}
.subnavi div.sub2 > .item.init {
  margin-left: 0;
}
.subnavi div.sub2 > .item.exit {
  margin-right: 0;
}
.subnavi div.sub2 > .item > .menu {
  font-size: 18px;
  line-height: 1.22222222;
  --s-linkcolor: #0059AC;
  --s-linkcolor-hover: #313638;
  transition: all 0.4s;
  text-decoration: none;
}
.subnavi div.sub2 > .item > .menu.path {
  --s-linkcolor: #313638;
  text-decoration: underline;
}
@media (max-width: 1023px) {
  .subnavi div.sub2 {
    width: max-content;
  }
  .subnavi div.sub2 > .item {
    margin: 0 7px;
  }
  .subnavi div.sub2 > .item > .menu {
    font-size: 12px;
    line-height: 1.5;
  }
}
#home {
  height: 26px;
}
.container--head {
  margin-top: 40px;
  margin-bottom: 80px;
}
.navi-contact .contact {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 10px;
}
.section--footer {
  margin-top: calc(var(--spaceTotal) * 2);
}
.footarea {
  margin-top: 40px;
  margin-bottom: 60px;
}
.footpart {
  width: 100%;
}
.footpart--right {
  margin-top: 38px;
}
.footpart--desktop {
  display: none;
}
.footpart--mobile {
  display: block;
}
.cbdModule--copyright {
  margin-top: 38px;
}
.services {
  margin-top: 28px;
}
.area--one .unitTwo .unit__content {
  margin-top: 19px;
}
.area--one .unitTwo .unit__background {
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.area--one .unitThree .unit__body {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.area--one .unitThree .part.pict:nth-child(3n+1) {
  width: 100%;
  aspect-ratio: 1.49056604;
}
@supports not (aspect-ratio: 790 /  530) {
  .area--one .unitThree .part.pict:nth-child(3n+1):before {
    float: left;
    padding-top: 67.08860759%;
    content: '';
  }
  .area--one .unitThree .part.pict:nth-child(3n+1):after {
    display: block;
    content: '';
    clear: both;
  }
}
.area--one .unitThree .part.pict:nth-child(3n+2),
.area--one .unitThree .part.pict:nth-child(3n+3) {
  aspect-ratio: 0.66666667;
  width: calc(50% - 5px);
}
@supports not (aspect-ratio: 790 /  1185) {
  .area--one .unitThree .part.pict:nth-child(3n+2):before,
  .area--one .unitThree .part.pict:nth-child(3n+3):before {
    float: left;
    padding-top: 150%;
    content: '';
  }
  .area--one .unitThree .part.pict:nth-child(3n+2):after,
  .area--one .unitThree .part.pict:nth-child(3n+3):after {
    display: block;
    content: '';
    clear: both;
  }
}
.area--one .unitFour .unit__background {
  width: 80px;
  max-width: 100%;
}
.area--one .unitFive .unit__body {
  padding-top: 53px;
  background-size: 45px 40px;
}
.area--one .unitFive--image .unit__content {
  margin-top: 39px;
}
.part--widget .cb-googlemapscontainer {
  height: 530px !important;
}
.partFileSize--social {
  margin-top: calc(var(--spacePart) * 4);
}
.ns-slider {
  float: left;
  width: 100%;
}
.ns-sliderWrapper {
  float: left;
  width: 100%;
  position: relative;
}
.area--one .unitSlider {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.ns-slider .ns-sliderIndex {
  float: left;
  width: 98.7654321%;
  margin-left: 0.625%;
  margin-top: 30px;
  position: absolute;
  bottom: 50px;
  box-sizing: border-box;
}
@media (max-width: 1023px) {
  .ns-slider .ns-sliderIndex {
    position: initial;
  }
}
.ns-slider .ns-sliderIndex__content {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
.ns-slider .ns-sliderIndex__item {
  float: left;
  width: 10px;
  height: 10px;
  border-radius: 10000px;
  background-color: #B3CDE5;
}
.ns-slider .ns-sliderIndex__item.is-active {
  background-color: #0059AC;
}
.ns-slider .ns-slider__section {
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ns-slider .ns-slider__cover {
  float: left;
  width: 100%;
  position: relative;
}
.ns-slider .ns-slider__stage {
  float: left;
  width: 100%;
  overflow: hidden;
}
.ns-slider .ns-slider__area {
  float: left;
  width: 100%;
  display: flex;
}
.ns-slider .ns-slide {
  float: left;
  width: 98.7654321%;
  margin-left: 0.625%;
  flex-shrink: 0;
  box-sizing: border-box;
  transition: margin 0.6s;
}
@media (max-width: 1023px) {
  .ns-slider .ns-slide {
    width: calc(100% - 2px);
    margin: 0 2px;
  }
}
.ns-slider .ns-slide__content {
  float: left;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
@media (max-width: 1023px) {
  .ns-slider .ns-slide__content {
    flex-direction: column;
  }
}
.ns-slider .ns-slide__section {
  float: left;
  position: relative;
  width: 100%;
}
.ns-slider .ns-slide__section--text {
  width: 62.5%;
  margin-top: 16px;
  margin-left: 40px;
  box-sizing: border-box;
}
@media (max-width: 1023px) {
  .ns-slider .ns-slide__section--text {
    width: 100%;
    margin-left: 0;
  }
}
@media (hover: hover) and (pointer: fine) {
  #view .ns-slider .ns-slide.is-anchor:hover .ns-slide__section--text,
  #view .ns-slider .ns-slide.is-anchor:focus .ns-slide__section--text {
    transform: translateY(0);
    opacity: 1;
  }
}
.ns-slider .ns-slider__part {
  float: left;
  width: 100%;
}
.ns-slider__part--text {
  color: var(--s-textcolor);
}
.ns-slider .ns-slide__section--background {
  width: 100%;
}
@media (max-width: 1023px) {
  .ns-slider .ns-slide__section--background {
    width: 100%;
  }
}
.ns-slider .ns-slider__part--image {
  aspect-ratio: 2;
  width: 100%;
  position: relative;
  z-index: 1;
  margin: 0;
}
@supports not (aspect-ratio: 2 /  1) {
  .ns-slider .ns-slider__part--image:before {
    float: left;
    padding-top: 50%;
    content: '';
  }
  .ns-slider .ns-slider__part--image:after {
    display: block;
    content: '';
    clear: both;
  }
}
.ns-slider .ns-slider__part--sliderTitle,
.ns-slider .ns-slider__part--sliderLead {
  text-align: center;
  max-width: 800px;
}
.ns-slider .ns-sliderControl--prev {
  left: -25px;
}
.ns-slider .ns-sliderControl--next {
  right: -25px;
}
.ns-slider__controls {
  position: absolute;
  width: 102.5%;
  top: 500px;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1300px) {
  .ns-slider__controls {
    top: 200px;
    width: 98vw;
  }
}
@media (max-width: 767px) {
  .ns-slider__controls {
    top: 50px;
  }
}
.ns-slider .ns-sliderControl {
  float: left;
  z-index: 10;
  height: 60px;
  width: 60px;
  border-radius: 100%;
  box-sizing: border-box;
  cursor: pointer;
  background-size: 35px 25px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-color: #FFFFFF;
  transition: all 0.4s;
}
@media (max-width: 1023px) {
  .ns-slider .ns-sliderControl {
    background-size: 25px 18px;
    height: 50px;
    width: 50px;
  }
}
.ns-slider .ns-sliderControl--prev {
  background-image: url(/images/arrow-slider-prev.svg);
}
@media (hover: hover) and (pointer: fine) {
  .ns-slider .ns-sliderControl--prev:hover,
  .ns-slider .ns-sliderControl--prev:focus {
    background-position: 30% 50%;
  }
}
.ns-slider .ns-sliderControl--next {
  background-image: url(/images/arrow-slider-next.svg);
}
@media (hover: hover) and (pointer: fine) {
  .ns-slider .ns-sliderControl--next:hover,
  .ns-slider .ns-sliderControl--next:focus {
    background-position: 70% 50%;
  }
}
.ns-unitFilter__unit {
  display: none;
}
.ns-unitFilter__unit.ns-unitFilter__unit--activated {
  display: block;
  opacity: 0;
  transform: translateY(-20px) scale(0.98);
  transition: transform 0.4s, opacity 0.4s !important;
}
.ns-unitFilter__unit.ns-unitFilter__unit--active {
  opacity: 1;
  transform: translateY(0) scale(1);
}
.ns-unitFilter {
  float: left;
  width: 100%;
  position: relative;
  margin-top: var(--spaceTotal);
}
@media (min-width: 1024px) {
  .ns-unitFilter__unit {
    --spaceUnit: 40px;
  }
  .ns-unitFilter__unit .part {
    --spacePart: 20px;
  }
}
.ns-unitFilter__wrapper {
  float: left;
  width: 100%;
}
.ns-unitFilter__toggle {
  display: none;
}
.ns-unitFilter__content {
  float: left;
  width: 101.25%;
  margin-left: -0.625%;
}
.ns-unitFilter__list {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.ns-unitFilter__actions {
  float: left;
  display: none;
}
@media (min-width: 1024px) {
  .ns-unitFilter__link--confirm {
    display: none;
  }
}
.ns-unitFilter__groupContent {
  float: left;
  width: 100%;
  overflow: hidden;
  max-height: 0;
}
.ns-unitFilter__group.is-open .ns-unitFilter__groupContent {
  max-height: initial;
}
.ns-unitFilter__item {
  color: #0059AC;
  text-decoration: none;
  cursor: pointer;
  margin: 0 5px;
  padding: 12px 17px;
  border-radius: 8px;
  line-height: 1;
}
.ns-unitFilter__item:hover,
.ns-unitFilter__item:focus,
.ns-unitFilter__item.is-active {
  background-color: #0059AC;
  color: #FFFFFF;
}
.ns-unitFilter__item:first-child {
  margin-left: 10px;
}
@media (max-width: 1023px) {
  .ns-unitFilter {
    overflow-x: scroll;
    width: 100vw;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .ns-unitFilter::-webkit-scrollbar {
    display: none;
  }
  .ns-unitFilter__content {
    width: calc(100% + 20px);
    margin-left: -10px;
  }
  .ns-unitFilter__list {
    flex-wrap: nowrap;
    width: max-content;
  }
  .ns-unitFilter__item {
    font-size: 14px;
    padding: 8px 12px;
  }
}
/*# sourceMappingURL=./screen-small.css.map */